.category-container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 20px;
    row-gap: 50px;
}

.category-title { 
    font-size: 38px;
    margin-bottom: 25px; 
    text-decoration: none; 
    text-align: center;
} 