.payment-success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #e0ffe0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h2 {
        color: #28a745;
        margin-bottom: 10px;
    }

    p {
        color: #333;
    }
}