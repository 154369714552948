.payment-form-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
    }

    .card-element {
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .payment-button-container {
        display: flex;
        justify-content: center;

        Button {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border-radius: 5px;
        }
    }
}