
.new-product-form-container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    

    h2{
        text-align: center;
    }

    span{
        text-align: center;
    }

    .form-input-label { 
        color: grey;
        font-size: 16px; 
        font-weight: normal; 
        pointer-events: none; 

    }
    

    .submit-button-container{
        display: flex;
        justify-content: center;
    }
    

}