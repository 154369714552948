.edit-form-container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;

    h2{
        text-align: center;
    }

    span{
        text-align: center;
    }

    .submit-button-container{
        display: flex;
        justify-content: center;
    }
    

}