.product-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    background-color: #f9f9f9;

    h1 {
        font-size: 24px;
        margin-bottom: 10px;
        color: #333;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    p {
        font-size: 18px;
        color: #666;
    }

    .product-display__buttons {
        display: flex;
        gap: 10px;
        margin-top: 10px;

        .edit-button, .delete-button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }

        .edit-button {
            background-color: #4CAF50;
            color: white;
        }

        .delete-button {
            background-color: #f44336;
            color: white;
        }
    }
}