.sign-up-container {
    display: flex;
    flex-direction: column;
    width: 380px;

    h2{
        margin: 10px 0;
    }

    .signin-button-container {
        display: flex;
        justify-content: center;
    }
}