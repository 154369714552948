.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .logo-container {
        height: 100%;
        width: 70px;
        padding: 20px;
        margin-right: auto;
    }

    .nav-links-container {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-link {
            justify-self: center;
            padding: 20px 30px;
            cursor: pointer;
        }

        .cart-link {
            height: 100%;
            margin-left: auto;
        }
        
        .admin-links-container{
            display: flex;
        }

    }
}
